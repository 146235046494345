export const includes = (collection, matchStr) => collection.indexOf(matchStr) > -1

export const camelToSnakeCase = inputstr => inputstr.replace(/([A-Z])/g, str => `_${str.toLowerCase()}`)

export const displayQuotedWaitTimesPreview = venueId => {
  const xhr = new XMLHttpRequest()
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      document.getElementById('sr-wait-preview-text').innerHTML += JSON.parse(xhr.responseText)
    }
  }
  xhr.open('GET', `/direct/${venueId}/wait_time_preview`, true)
  xhr.send()
}

export const checkHijackStatus = (venueId, triggerId) => {
  const xhr = new XMLHttpRequest()
  xhr.onreadystatechange = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      const response = JSON.parse(xhr.responseText)
      if (response.hijack && response.button_text) {
        document.getElementById(triggerId).lastChild.innerHTML = response.button_text
      }
    }
  }

  // IE does not support currentScript, so default to prod
  let domainpath = 'https://www.sevenrooms.com'
  if (document.currentScript && document.currentScript.src) {
    const { src } = document.currentScript
    // In old country, we let DOM spec do parse for us
    const a = document.createElement('a')
    a.href = src
    domainpath = `${a.protocol}//${a.host}`
  }

  xhr.open('GET', `${domainpath}/direct/${venueId}/reservation_widget_hijack_status`, true)
  xhr.send()
}

export const buildSevenroomsButton = (srButtonId, previewWaitTimes = false) => {
  const srButton = document.getElementById(srButtonId)
  const text = srButton.textContent
  srButton.textContent = ''
  const classes = srButton.className.split(' ')
  const color = classes[2].slice(3)
  const iTag = document.createElement('i')
  iTag.className += 'sr-fa sr-fa-logo'
  const buttonText = document.createElement('span')
  buttonText.textContent = text
  const previewWaitDiv = document.createElement('div')
  previewWaitDiv.style.cssText = 'display: flex; justify-content: center;font-family: Roboto; font-style: normal; letter-spacing: 0;'
  const waitTimeIcon = document.createElement('i')
  waitTimeIcon.className += 'sr-fa sr-fa-timer'
  waitTimeIcon.style.cssText = 'display: block; float: left;'
  const waitPreviewText = document.createElement('span')
  waitPreviewText.style.cssText = 'max-width: 87px; display: inline-block; overflow: hidden;'
  waitPreviewText.id = 'sr-wait-preview-text'
  waitPreviewText.textContent = ''
  previewWaitDiv.appendChild(waitTimeIcon)
  previewWaitDiv.appendChild(waitPreviewText)
  let buttonHeight
  let iconMargin
  if (classes[0] === 'sr-sm' && classes[1] === 'sr-dark') {
    buttonHeight = previewWaitTimes ? '45px' : '26px'
    iconMargin = previewWaitTimes ? '10px 0 0 10px' : '1px 0 0 6px'
    srButton.style.cssText = `cursor: pointer; border-radius: 3px; text-align: center; box-sizing: border-box; line-height: 15px; overflow: hidden; background-color: ${color}; color: white; font-weight: 100; padding: 5px 0px 7px 6px; width: 127px; font-size: 12px; height: ${buttonHeight};`
    iTag.style.cssText = `display: block; float: left; font-size: 13px; margin: ${iconMargin};`
    buttonText.style.cssText =
      'display: inline-block; overflow: hidden; margin-right: 7px; max-width: 87px;font-family: Roboto; font-style: normal; letter-spacing: 0;'
    previewWaitDiv.style.cssText += 'font-size: 10px;'
    waitTimeIcon.style.cssText += 'font-size: 10px; margin: 2px 5px 0px 6px;'
    waitPreviewText.style.cssText += 'margin-right: 16px;'
  } else if (classes[0] === 'sr-sm' && classes[1] === 'sr-light') {
    buttonHeight = previewWaitTimes ? '45px' : '26px'
    iconMargin = previewWaitTimes ? '10px 0 0 10px' : '1px 0 0 6px'
    srButton.style.cssText = `cursor: pointer; border-radius: 3px; text-align: center; box-sizing: border-box; line-height: 15px; overflow: hidden; background-color: white; color: ${color}; border: 1px solid ${color}; font-weight: 400; padding: 5px 0px 7px 6px; width: 127px; font-size: 12px; height: ${buttonHeight};`
    iTag.style.cssText = `display: block; float: left; font-size: 13px; margin: ${iconMargin};`
    buttonText.style.cssText =
      'display: inline-block; overflow: hidden; margin-right: 7px; max-width: 87px;font-family: Roboto; font-style: normal; letter-spacing: 0;'
    previewWaitDiv.style.cssText += 'font-size: 10px;'
    waitTimeIcon.style.cssText += 'font-size: 10px; margin: 2px 5px 0px 6px;'
    waitPreviewText.style.cssText += 'margin-right: 16px;'
  } else if (classes[0] === 'sr-md' && classes[1] === 'sr-dark') {
    buttonHeight = previewWaitTimes ? '50px' : '35px'
    iconMargin = previewWaitTimes ? '6px 0 0 10px' : '0 0 0 4px'
    srButton.style.cssText = `cursor: pointer; border-radius: 3px; text-align: center; box-sizing: border-box; line-height: 15px; overflow: hidden; background-color: ${color}; color: white; font-weight: 100; padding: 9px 0px 12px 10px; width: 152px; font-size: 14px; height: ${buttonHeight};`
    iTag.style.cssText = `display: block; float: left; font-size: 17px; margin: ${iconMargin};`
    buttonText.style.cssText =
      'display: inline-block; overflow: hidden; margin-right: 7px; max-width: 100px;font-family: Roboto; font-style: normal; letter-spacing: 0;'
    previewWaitDiv.style.cssText += 'font-size: 11px;'
    waitTimeIcon.style.cssText += 'font-size: 13px; margin: 1px 5px 0 6px;'
    waitPreviewText.style.cssText += 'margin-right: 14px;'
  } else if (classes[0] === 'sr-md' && classes[1] === 'sr-light') {
    buttonHeight = previewWaitTimes ? '50px' : '35px'
    iconMargin = previewWaitTimes ? '6px 0 0 10px' : '0 0 0 4px'
    srButton.style.cssText = `cursor: pointer; border-radius: 3px; text-align: center; box-sizing: border-box; line-height: 15px; overflow: hidden; background-color: white; color: ${color}; border: 1px solid ${color}; font-weight: 400; padding: 9px 0px 12px 10px; width: 152px; font-size: 14px; height: ${buttonHeight};`
    iTag.style.cssText = `display: block; float: left; font-size: 17px; margin: ${iconMargin};`
    buttonText.style.cssText =
      'display: inline-block; overflow: hidden; margin-right: 7px; max-width: 100px;font-family: Roboto; font-style: normal; letter-spacing: 0;'
    previewWaitDiv.style.cssText += 'font-size: 11px;'
    waitTimeIcon.style.cssText += 'font-size: 13px; margin: 1px 5px 0 6px;'
    waitPreviewText.style.cssText += 'margin-right: 14px;'
  } else if (classes[0] === 'sr-lg' && classes[1] === 'sr-dark') {
    buttonHeight = previewWaitTimes ? '70px' : '45px'
    iconMargin = previewWaitTimes ? '10px 0 0 10px' : '-3px 0 0 2px'
    srButton.style.cssText = `cursor: pointer; border-radius: 3px; text-align: center; box-sizing: border-box; line-height: 15px; overflow: hidden; background-color: ${color}; color: white; font-weight: 100; padding: 14px 0px 17px 10px; width: 178px; font-size: 18px; height: ${buttonHeight};`
    iTag.style.cssText = `display: block; float: left; font-size: 21px; margin: ${iconMargin};`
    buttonText.style.cssText =
      'display: inline-block; overflow: hidden; margin-right: 7px; max-width: 130px;font-family: Roboto; font-style: normal; letter-spacing: 0;'
    previewWaitDiv.style.cssText += 'font-size: 13px; margin-top: 8px;'
    waitTimeIcon.style.cssText += 'font-size: 17px; margin: 0 5px 0 4px;'
    waitPreviewText.style.cssText += 'margin-right: 12px;'
  } else if (classes[0] === 'sr-lg' && classes[1] === 'sr-light') {
    buttonHeight = previewWaitTimes ? '70px' : '45px'
    iconMargin = previewWaitTimes ? '10px 0 0 10px' : '-3px 0 0 2px'
    srButton.style.cssText = `cursor: pointer; border-radius: 3px; text-align: center; box-sizing: border-box; line-height: 15px; overflow: hidden; background-color: white; color: ${color}; border: 1px solid ${color}; font-weight: 400; padding: 14px 0px 17px 10px; width: 178px; font-size: 18px; height: ${buttonHeight};`
    iTag.style.cssText = `display: block; float: left; font-size: 21px; margin: ${iconMargin};`
    buttonText.style.cssText =
      'display: inline-block; overflow: hidden; margin-right: 7px; max-width: 130px;font-family: Roboto; font-style: normal; letter-spacing: 0;'
    previewWaitDiv.style.cssText += 'font-size: 13px; margin-top: 8px;'
    waitTimeIcon.style.cssText += 'font-size: 17px; margin: 0 5px 0 4px;'
    waitPreviewText.style.cssText += 'margin-right: 12px;'
  }
  srButton.appendChild(iTag)
  srButton.appendChild(buttonText)
  if (previewWaitTimes) {
    srButton.appendChild(previewWaitDiv)
  }
}
