import { includes, buildSevenroomsButton, displayQuotedWaitTimesPreview } from './helpers'
import WidgetLauncher from './WidgetLauncher'

export default class WaitlistWidgetLauncher extends WidgetLauncher {
  constructor() {
    super()
    this.isVisible = false
    this.resizeFlag = true

    this.domStyles = {
      native: {
        documentElement: {
          overflow: document.documentElement.style.overflow,
          height: document.documentElement.style.height,
        },
        body: {
          position: document.body.style.position,
          minHeight: document.body.style.minHeight,
          overflow: document.body.style.overflow,
        },
      },
      widget: {
        documentElement: {
          overflow: 'hidden',
          height: '100%',
        },
        body: {
          position: this.isIOS ? 'relative' : document.body.style.position,
          minHeight: '100%',
          overflow: this.isIOS ? 'visible' : 'hidden',
        },
      },
    }

    this.initWidget = this.initWidget.bind(this)
    this.resizeHandler = this.resizeHandler.bind(this)
    this.messageHandler = this.messageHandler.bind(this)
    this.setWidgetWidth = this.setWidgetWidth.bind(this)
    this.dismissWidget = this.dismissWidget.bind(this)
    this.domElements = buildWaitlistWidgetScene(this)

    this.launchOnInit = !!this.queryObj.triggerSevenrooms
  }

  validateWidgetConfig(config) {
    if (config.type !== 'waitlist') {
      throw new Error('Invalid field:type in the SevenroomsWidget configuration object')
    } else if (!document.getElementById(config.triggerId) && document.querySelectorAll(config.triggerSelector).length === 0) {
      throw new Error('Invalid field:triggerId or invalid field:triggerSelector in the SevenroomsWidget configuration object')
    } else if (typeof config.venueId !== 'string') {
      throw new Error('Invalid field:venueId in the SevenroomsWidget configuration object')
    } else if (!includes(['boolean', 'undefined'], typeof config.styleButton)) {
      throw new Error('Invalid field:styleButton in the SevenroomsWidget configuration object')
    }
  }

  triggerWidgetLauncher(config, _event) {
    const iframeSrc = `${this.getDomain(config.env)}/waitlist/${config.venueId}`
    this.domElements.iframe.src = iframeSrc
    this.domElements.iframe.id = 'sevenrooms-form'

    this.setWidgetDOMStyles()
    if (this.getIsIOS()) {
      window.scrollTo(0, 0)
    }
    document.body.appendChild(this.domElements.scene)
    document.head.appendChild(this.domElements.meta)

    this.isVisible = true

    this.resizeHandler()
    window.addEventListener('resize', this.resizeHandler)
    window.addEventListener('message', this.messageHandler)
    document.onkeydown = event => {
      if (event.keyCode === 27 /* Escape */) {
        this.dismissWidget()
      }
    }
  }

  initWidget(config) {
    this.validateWidgetConfig(config)
    if (config.styleButton) {
      this.loadFontStylesheet()
      buildSevenroomsButton(config.triggerId, config.previewWaitTimes)
      if (config.previewWaitTimes) {
        displayQuotedWaitTimesPreview(config.venueId)
      }
    }

    const triggerElements = config.triggerId
      ? [document.getElementById(config.triggerId)]
      : document.querySelectorAll(config.triggerSelector)
    const triggerWidgetLauncher = this.triggerWidgetLauncher.bind(this, config)

    for (let i = 0; i < triggerElements.length; i += 1) {
      triggerElements[i].addEventListener('click', triggerWidgetLauncher)
    }

    if (this.launchOnInit) {
      triggerWidgetLauncher()
      this.launchOnInit = false
    }
  }

  dismissWidget(_event) {
    if (this.isVisible) {
      this.resetDOMStyles()
      document.body.removeChild(this.domElements.scene)
      document.head.removeChild(this.domElements.meta)
      this.isVisible = false
      window.removeEventListener('resize', this.resizeHandler)
      window.removeEventListener('message', this.messageHandler)
    }
  }

  setWidgetWidth() {
    let width = document.body.clientWidth - 20
    if (width > 700) {
      width = 700
    } else if (width < 300) {
      width = 300
    }
    if ((document.body.clientWidth - width) % 2 !== 0) {
      width -= 1
    }
    this.domElements.container.style.width = `${width}px`
    this.resizeFlag = !this.resizeFlag
  }

  resizeHandler(_event) {
    if (this.resizeFlag) {
      if (typeof requestAnimationFrame === 'undefined') {
        this.setWidgetWidth()
      } else {
        requestAnimationFrame(this.setWidgetWidth)
      }
    } else {
      this.resizeFlag = !this.resizeFlag
    }
  }

  messageHandler(event) {
    if (event.data.type === 'heightEvent') {
      if (event.data.size !== parseFloat(this.domElements.container.style.height, 10)) {
        const widgetHeight = event.data.size + 1
        this.domElements.container.style.height = `${widgetHeight}px`
        this.domElements.cover.style.height = `${widgetHeight + 195}px`
      }
    } else if (event.data.type === 'dismiss') {
      this.dismissWidget()
    }
  }
}

function buildWaitlistWidgetScene(WUtil) {
  const scene = document.createElement('div')
  const cover = document.createElement('div')
  const container = document.createElement('div')
  const meta = document.createElement('meta')
  const xButton = document.createElement('button')
  const iframe = document.createElement('iframe')

  scene.style.top = '0px'
  scene.style.right = '0px'
  scene.style.bottom = '0px'
  scene.style.left = '0px'
  scene.style.zIndex = '9999999'
  scene.style.webkitOverflowScrolling = 'touch'
  scene.style.position = 'fixed'
  if (WUtil.getIsIOS()) {
    scene.style.height = `${document.documentElement.clientHeight}px`
    scene.style.overflowY = 'auto'
  } else {
    scene.style.overflowY = 'hidden'
  }

  cover.style.background = 'rgba(26,25,25,0.901961)'
  cover.style.top = '0px'
  cover.style.right = '0px'
  cover.style.left = '0px'
  cover.style.minHeight = '100%'
  cover.style.height = '395px'
  cover.style.opacity = '1'
  cover.style.position = 'absolute'

  container.style.boxShadow = 'rgba(0,0,0,0.298039) 0px 0px 3px'
  container.style.height = '200px'
  container.style.margin = '40px auto 0'
  container.style.opacity = '1'
  container.style.position = 'relative'
  container.style.transform = 'translate3d(0px, 0px, 0px)'

  meta.name = 'viewport'
  meta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'

  xButton.style.color = 'rgb(255, 255, 255)'
  xButton.style.cursor = 'pointer'
  xButton.style.fontFamily = 'sans serif'
  xButton.style.position = 'absolute'
  xButton.style.fontSize = '36px'
  xButton.style.fontWeight = '200'
  xButton.style.right = '0px'
  xButton.style.top = '-44px'
  xButton.style.width = '40px'
  xButton.style.lineHeight = '44px'
  xButton.style.textAlign = 'right'
  xButton.style.backgroundColor = 'transparent'
  xButton.style.border = '0'
  xButton.textContent = '×'

  xButton.addEventListener('click', WUtil.dismissWidget)

  iframe.style.border = 'none'
  iframe.style.height = '90vh'
  iframe.style.width = '100%'
  iframe.style.backgroundColor = 'transparent'

  scene.appendChild(cover)
  scene.appendChild(container)
  container.appendChild(xButton)
  container.appendChild(iframe)

  return { scene, cover, container, meta, xButton, iframe }
}
