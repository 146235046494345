import { includes, buildSevenroomsButton, camelToSnakeCase } from './helpers'
import WidgetLauncher from './WidgetLauncher'

export default class EventsWidgetLauncher extends WidgetLauncher {
  constructor() {
    super()
    this.domElements = buildEventsWidgetScene(this)

    this.domStyles = {
      native: {
        documentElement: {
          overflow: document.documentElement.style.overflow,
          height: document.documentElement.style.height,
        },
        body: {
          position: document.body.style.position,
          minHeight: document.body.style.minHeight,
          overflow: document.body.style.overflow,
          marginTop: document.body.style.marginTop,
        },
      },
      widget: {
        documentElement: {
          overflow: 'hidden',
          height: '100%',
        },
        body: {
          position: this.isIOS ? 'relative' : document.body.style.position,
          minHeight: '100%',
          overflow: this.isIOS ? 'visible' : 'hidden',
          marginTop: '0px',
        },
      },
    }

    this.initWidget = this.initWidget.bind(this)
  }

  validateWidgetConfig(config) {
    if (!includes(['events', 'reservations'], config.type)) {
      throw new Error('Invalid field:type in the SevenroomsWidget configuration object')
    } else if (!document.getElementById(config.triggerId) && document.querySelectorAll(config.triggerSelector).length === 0) {
      throw new Error('Invalid field:triggerId or invalid field:triggerSelector in the SevenroomsWidget configuration object')
    } else if (typeof config.venueId !== 'string') {
      throw new Error('Invalid field:venueId in the SevenroomsWidget configuration object')
    } else if (!includes(['boolean', 'undefined'], typeof config.styleButton)) {
      throw new Error('Invalid field:styleButton in the SevenroomsWidget configuration object')
    }
  }

  triggerWidgetLauncher(config, event) {
    let iframeSrc = `${this.getDomain(config.env)}/events/${config.venueId}`

    if (config.trackingSlug) {
      iframeSrc += `/${config.trackingSlug}`
    }

    const validUriParams = ['eventId', 'date', 'clientToken']
    const uriQuery = Object.keys(config)
      .filter(key => includes(validUriParams, key))
      .map(key => `${camelToSnakeCase(key)}=${encodeURIComponent(config[key])}`)

    if (uriQuery.length > 0) {
      iframeSrc += `?${uriQuery.join('&')}`
    }
    this.domElements.iframe.src = iframeSrc
    this.domElements.iframe.allow = 'payment'

    this.setWidgetDOMStyles()
    if (this.getIsIOS() || config.scrollTop) {
      window.scrollTo(0, 0)
    }
    document.body.appendChild(this.domElements.scene)
  }

  initWidget(config) {
    this.validateWidgetConfig(config)
    if (config.styleButton) {
      this.loadFontStylesheet()
      buildSevenroomsButton(config.triggerId)
    }
    const triggerElements = config.triggerId
      ? [document.getElementById(config.triggerId)]
      : document.querySelectorAll(config.triggerSelector)
    const triggerWidgetLauncher = this.triggerWidgetLauncher.bind(this, config)

    for (let i = 0; i < triggerElements.length; i++) {
      triggerElements[i].addEventListener('click', triggerWidgetLauncher)
    }
  }
}

function buildEventsWidgetScene(WUtil) {
  const scene = document.createElement('div')
  const cover = document.createElement('div')
  const container = document.createElement('div')
  const xDiv = document.createElement('div')
  const iframe = document.createElement('iframe')
  const footer = document.createElement('img')

  scene.style.top = '0px'
  scene.style.left = '0px'
  scene.style.height = '100%'
  scene.style.width = '100%'
  scene.style.position = 'absolute'
  scene.style.zIndex = '9999998'

  cover.style.background = 'rgba(26,25,25,0.901961)'
  cover.style.top = '0px'
  cover.style.left = '0px'
  cover.style.height = '120%'
  cover.style.width = '100%'
  cover.style.opacity = '1'
  cover.style.position = 'fixed'

  container.style.boxShadow = 'rgba(0,0,0,0.298039) 0px 0px 3px'
  container.style.height = '88vh'
  container.style.margin = '6vh auto 0'
  container.style.width = '100%'
  container.style.maxWidth = '700px'
  container.style.minWidth = '300px'
  container.style.opacity = '1'
  container.style.position = 'relative'

  xDiv.style.position = 'absolute'
  xDiv.style.color = 'rgb(153,153,153)'
  xDiv.style.fontSize = '5.2vh'
  xDiv.style.fontWeight = 'normal'
  xDiv.style.cursor = 'pointer'
  xDiv.style.fontFamily = 'Roboto'
  xDiv.style.right = '1.5vw'
  xDiv.style.top = '-6vh'
  xDiv.textContent = '×'

  xDiv.addEventListener('click', event => {
    WUtil.resetDOMStyles()
    document.body.removeChild(scene)
  })

  iframe.style.border = 'none'
  iframe.style.height = '100%'
  iframe.style.width = '100%'
  iframe.style.backgroundColor = 'transparent'

  footer.style.position = 'relative'
  footer.style.bottom = '-2vh'
  footer.style.height = '18px'
  footer.style.display = 'block'
  footer.style.margin = '0 auto'
  footer.src = 'https://www.sevenrooms.com/images/widget/sevenrooms_footer.png'

  scene.appendChild(cover)
  scene.appendChild(container)
  container.appendChild(xDiv)
  container.appendChild(iframe)
  scene.appendChild(footer)

  return { scene, cover, container, xDiv, iframe, footer }
}
