import { includes } from './helpers'

export default class WidgetLauncher {
  constructor() {
    this.CAMPAIGN_KEY = 'SR.CAMPAIGN.v1'
    this.UNIX_EXPIRY = 604800 // 1 week
    this.isSevenroomsHost =
      includes(window.location.host, 'sevenrooms') ||
      includes(window.location.host, 'local.sevenrooms.com') ||
      includes(window.location.host, 'localhost')
    this.isIOS = /(iPad|iPhone|iPod)/g.test(navigator.userAgent)
    this.styleSheetLoaded = false
    this.queryObj = this.decodeQueryString()
    if (this.queryObj.campaign) {
      try {
        this.setCampaign(this.queryObj.campaign)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Failed to set SEVENROOMS ad campaign', err)
      }
    }
  }

  getIsSevenroomsHost() {
    return this.isSevenroomsHost
  }

  getDomain(env) {
    if (this.getIsSevenroomsHost()) {
      // When in development mode, return '' for relative path
      return ''
    }
    switch (env) {
      case 'PROD':
        return 'https://www.sevenrooms.com'
      case 'DEMO':
        return 'https://demo.sevenrooms.com'
      case 'EU':
        return 'https://eu.sevenrooms.com'
      case 'QA':
        return 'https://qa.sevenrooms.com'
      default:
        return 'https://www.sevenrooms.com'
    }
  }

  getIsIOS() {
    return this.isIOS
  }

  getCampaign() {
    if (this.queryObj.campaign) {
      return this.queryObj.campaign
    } else if (localStorage) {
      const storedCampaign = localStorage.getItem(this.CAMPAIGN_KEY) // returns null if non-existent
      if (!storedCampaign) {
        return null
      }
      const [campaignLabel, campaignTimeStamp] = storedCampaign.split('##')
      const timestampDiff = this._generateTimestamp() - Number(campaignTimeStamp)
      if (timestampDiff > this.UNIX_EXPIRY) {
        this.removeCampaign()
        return null
      }
      return campaignLabel
    }
    return null
  }

  // post: return boolean for success or failure
  setCampaign(campaign) {
    if (localStorage && campaign) {
      localStorage.setItem(this.CAMPAIGN_KEY, this._generateCampaign(campaign))
      return true
    }
    return false
  }

  removeCampaign() {
    if (localStorage) {
      localStorage.removeItem(this.CAMPAIGN_KEY)
      return true
    }
    return false
  }

  _generateCampaign(campaign) {
    return [campaign, String(this._generateTimestamp())].join('##')
  }

  _generateTimestamp() {
    return Math.round(new Date().getTime() / 1000)
  }

  resetDOMStyles() {
    Object.keys(this.domStyles.native).forEach(tag => {
      Object.keys(this.domStyles.native[tag]).forEach(attr => {
        document[tag].style[attr] = this.domStyles.native[tag][attr]
      })
    })
  }

  setWidgetDOMStyles() {
    Object.keys(this.domStyles.widget).forEach(tag => {
      Object.keys(this.domStyles.widget[tag]).forEach(attr => {
        document[tag].style[attr] = this.domStyles.widget[tag][attr]
      })
    })
  }

  loadFontStylesheet() {
    if (this.styleSheetLoaded) {
      return
    }
    this.styleSheetLoaded = true
    const logoCssHref = this.isSevenroomsHost ? '/css/logo.css' : 'https://www.sevenrooms.com/css/logo.css'
    this.addHeadLink('stylesheet', 'text/css', logoCssHref)
    const fontsCssHref = this.isSevenroomsHost ? '/css/embed-fonts.css' : 'https://www.sevenrooms.com/css/embed-fonts.css'
    this.addHeadLink('stylesheet', 'text/css', fontsCssHref)
  }

  addHeadLink(rel, ttype, href) {
    const link = document.createElement('link')
    link.rel = rel
    link.type = ttype
    link.href = href
    document.head.appendChild(link)
  }

  decodeQueryString() {
    const queryStr = decodeURIComponent(window.location.search.substr(1))
    if (!queryStr) {
      return {}
    }
    return queryStr.split('&').reduce((accum, queryParam) => {
      const [key, val] = queryParam.split('=')
      return { ...accum, [key]: val }
    }, {})
  }
}
