if (!Element.prototype.addEventListener) {
  const oListeners = {}
  function runListeners(oEvent) {
    if (!oEvent) {
      oEvent = window.event
    }
    for (let iLstId = 0, iElId = 0, oEvtListeners = oListeners[oEvent.type]; iElId < oEvtListeners.aEls.length; iElId++) {
      if (oEvtListeners.aEls[iElId] === this) {
        for (iLstId; iLstId < oEvtListeners.aEvts[iElId].length; iLstId++) {
          oEvtListeners.aEvts[iElId][iLstId].call(this, oEvent)
        }
        break
      }
    }
  }
  Element.prototype.addEventListener = function (sEventType, fListener /* , useCapture (will be ignored!) */) {
    if (oListeners.hasOwnProperty(sEventType)) {
      const oEvtListeners = oListeners[sEventType]
      for (var nElIdx = -1, iElId = 0; iElId < oEvtListeners.aEls.length; iElId++) {
        if (oEvtListeners.aEls[iElId] === this) {
          nElIdx = iElId
          break
        }
      }
      if (nElIdx === -1) {
        oEvtListeners.aEls.push(this)
        oEvtListeners.aEvts.push([fListener])
        this[`on${sEventType}`] = runListeners
      } else {
        const aElListeners = oEvtListeners.aEvts[nElIdx]
        if (this[`on${sEventType}`] !== runListeners) {
          aElListeners.splice(0)
          this[`on${sEventType}`] = runListeners
        }
        for (let iLstId = 0; iLstId < aElListeners.length; iLstId++) {
          if (aElListeners[iLstId] === fListener) {
            return
          }
        }
        aElListeners.push(fListener)
      }
    } else {
      oListeners[sEventType] = { aEls: [this], aEvts: [[fListener]] }
      this[`on${sEventType}`] = runListeners
    }
  }
  Element.prototype.removeEventListener = function (sEventType, fListener /* , useCapture (will be ignored!) */) {
    if (!oListeners.hasOwnProperty(sEventType)) {
      return
    }
    const oEvtListeners = oListeners[sEventType]
    for (var nElIdx = -1, iElId = 0; iElId < oEvtListeners.aEls.length; iElId++) {
      if (oEvtListeners.aEls[iElId] === this) {
        nElIdx = iElId
        break
      }
    }
    if (nElIdx === -1) {
      return
    }
    for (let iLstId = 0, aElListeners = oEvtListeners.aEvts[nElIdx]; iLstId < aElListeners.length; iLstId++) {
      if (aElListeners[iLstId] === fListener) {
        aElListeners.splice(iLstId, 1)
      }
    }
  }
}
