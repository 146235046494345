import ReactDOM from 'react-dom'
import { SupportedLanguageCodes } from '@sevenrooms/core/api'
import { buttonOptions } from '@sevenrooms/mgr-settings-subscription-widget/SubscriptionWidgetSettings/ButtonPicker'
import WidgetLauncher from '../WidgetLauncher'
import { SubscriptionWidgetEmbed } from './SubscriptionWidgetEmbed'

interface Config {
  env: string
  triggerId: string
  type: string
  venueId: string
}

export class SubscriptionWidgetLauncher extends WidgetLauncher {
  validateWidgetConfig(config: Config) {
    if (config.type !== 'subscription') {
      throw new Error('Invalid field:type in the SevenroomsWidget configuration object')
    } else if (!document.getElementById(config.triggerId)) {
      throw new Error('Invalid field:triggerId in the SevenroomsWidget configuration object')
    }
  }

  initWidget = (config: Config) => {
    this.validateWidgetConfig(config)
    this.loadFontStylesheet()

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- get element is validated prior
    const srButton = document.getElementById(config.triggerId)!
    const { buttonId } = srButton.dataset
    const color = srButton.dataset.color || '#09223A'
    const buttonConfig = (buttonId && buttonOptions.find(({ id }) => id === parseInt(buttonId))) || buttonOptions[2]
    const lang =
      SupportedLanguageCodes.find(
        supportedLang => navigator.languages?.find(browserLang => browserLang === supportedLang) || navigator.language === supportedLang
      ) ?? 'en'

    ReactDOM.render(
      <SubscriptionWidgetEmbed
        color={color}
        buttonConfig={buttonConfig}
        buttonText={srButton.textContent || 'Subscribe to Emails'}
        embedSrc={`${this.getDomain(config.env)}/explore/${config.venueId}/subscription?embed=1&lang=${lang}`}
        lang={lang}
      />,
      document.getElementById(config.triggerId) as HTMLElement
    )
  }
}
