import { createFocusTrap } from 'focus-trap'
import _ from 'lodash'
import { tabbable } from 'tabbable'
import { includes, buildSevenroomsButton, camelToSnakeCase, checkHijackStatus } from './helpers'
import WidgetLauncher from './WidgetLauncher'

export default class ResWidgetLauncher extends WidgetLauncher {
  constructor() {
    super()
    this.isVisible = false
    this.resizeFlag = true

    this.domStyles = {
      native: {
        documentElement: {
          overflow: document.documentElement.style.overflow,
          height: document.documentElement.style.height,
        },
        body: {
          position: document.body.style.position,
          minHeight: document.body.style.minHeight,
          overflow: document.body.style.overflow,
        },
      },
      widget: {
        documentElement: {
          overflow: 'hidden',
          height: '100%',
        },
        body: {
          position: this.isIOS ? 'relative' : document.body.style.position,
          minHeight: '100%',
          overflow: this.isIOS ? 'visible' : 'hidden',
        },
      },
    }

    this.initWidget = this.initWidget.bind(this)
    this.resizeHandler = this.resizeHandler.bind(this)
    this.messageHandler = this.messageHandler.bind(this)
    this.setWidgetWidth = this.setWidgetWidth.bind(this)
    this.dismissWidget = this.dismissWidget.bind(this)
    this.domElements = buildResWidgetScene(this)

    this.launchOnInit = !!this.queryObj.triggerSevenrooms
  }

  validateWidgetConfig(config) {
    if (config.type !== 'reservations') {
      throw new Error('Invalid field:type in the SevenroomsWidget configuration object')
    } else if (!document.getElementById(config.triggerId) && document.querySelectorAll(config.triggerSelector).length === 0) {
      throw new Error('Invalid field:triggerId or invalid field:triggerSelector in the SevenroomsWidget configuration object')
    } else if (typeof config.venueId !== 'string') {
      throw new Error('Invalid field:venueId in the SevenroomsWidget configuration object')
    } else if (!includes(['boolean', 'undefined'], typeof config.styleButton)) {
      throw new Error('Invalid field:styleButton in the SevenroomsWidget configuration object')
    }
  }

  triggerWidgetLauncher(config) {
    let resultConfig = config

    let iframeSrc = `${this.getDomain(resultConfig.env)}/reservations/${resultConfig.venueId}`

    if (resultConfig.trackingSlug) {
      iframeSrc += `/${resultConfig.trackingSlug}`
    }

    const validUriParams = [
      'campaign',
      'clientId',
      'durationPicker',
      'defaultDate',
      'defaultPartySize',
      'defaultTime',
      'defaultDuration',
      'experienceId',
      'showDesc',
      'redirect', // 'button' | 'direct' | 'abandon'
      'return_button_text',
      'return_url',
      '_gl',
      'crossDomainSessionId',
      'crossDomainClientId',
    ]

    if (resultConfig.clientToken) {
      validUriParams.push('clientToken')
    }

    if ('return_url' in resultConfig) {
      resultConfig = {
        ...resultConfig,
        return_url: encodeURIComponent(resultConfig.return_url),
      }
    }

    const uriQuery = Object.keys(resultConfig)
      .filter(key => includes(validUriParams, key))
      .map(key => `${camelToSnakeCase(key)}=${resultConfig[key]}`)

    if (resultConfig.allVenues) {
      uriQuery.push(`venues=${resultConfig.allVenues}`)
    }

    if (uriQuery.length > 0) {
      iframeSrc += `?${uriQuery.join('&')}`
    }

    if (resultConfig.redirect) {
      document.location = iframeSrc
      return iframeSrc
    }

    this.domElements.iframe.src = iframeSrc
    this.domElements.iframe.id = 'sevenrooms-form'
    this.domElements.iframe.allow = 'payment'

    this.setWidgetDOMStyles()
    if (this.getIsIOS()) {
      window.scrollTo(0, 0)
    }
    document.body.appendChild(this.domElements.scene)
    document.head.appendChild(this.domElements.meta)

    this.isVisible = true

    this.resizeHandler()
    window.addEventListener('resize', this.resizeHandler)
    window.addEventListener('message', this.messageHandler)
    document.onkeydown = event => {
      if (event.keyCode === 27 /* Escape */) {
        this.dismissWidget()
      }
    }

    if (!this.focusTrap) {
      this.focusTrap = createFocusTrap(this.domElements.container, {
        initialFocus: this.domElements.xButton,
        fallbackFocus: this.domElements.xButton,
        onActivate: () => {
          this.domElements.container.className = 'trap is-active'
        },
        onDeactivate: () => {
          this.domElements.container.className = 'trap'
        },
      })

      this.domElements.xButton.addEventListener('blur', event => {
        if (event.relatedTarget === this.domElements.focusable) {
          window.setTimeout(() => {
            const tabbableElements = tabbable(this.domElements.iframe.contentDocument)
            const lastTabbableElement = _.last(tabbableElements)
            lastTabbableElement.focus()
          }, 0)
        }
      })

      this.domElements.focusable.addEventListener(
        'focus',
        event => {
          if (!event.relatedTarget) {
            window.setTimeout(() => {
              this.domElements.xButton.focus()
            }, 0)
          }
        },
        true
      )

      this.focusTrap.activate()
    }

    return undefined
  }

  initWidget(config) {
    let resultConfig = config

    this.validateWidgetConfig(resultConfig)
    if (resultConfig.styleButton) {
      this.loadFontStylesheet()
      buildSevenroomsButton(resultConfig.triggerId)
    }

    checkHijackStatus(resultConfig.venueId, resultConfig.triggerId)

    const campaign = this.getCampaign()
    if (campaign) {
      resultConfig = {
        ...resultConfig,
        campaign,
      }
    }

    const triggerElements = resultConfig.triggerId
      ? [document.getElementById(resultConfig.triggerId)]
      : document.querySelectorAll(resultConfig.triggerSelector)

    const triggerWidgetLauncher = this.triggerWidgetLauncher.bind(this, resultConfig)

    for (let i = 0; i < triggerElements.length; i += 1) {
      triggerElements[i].addEventListener('click', triggerWidgetLauncher)
    }

    if (this.launchOnInit) {
      triggerWidgetLauncher()
      this.launchOnInit = false
    }
  }

  dismissWidget() {
    if (this.isVisible) {
      if (this.focusTrap) {
        this.focusTrap.deactivate()
        this.focusTrap = null
      }

      this.resetDOMStyles()
      document.body.removeChild(this.domElements.scene)
      document.head.removeChild(this.domElements.meta)
      this.isVisible = false
      window.removeEventListener('resize', this.resizeHandler)
      window.removeEventListener('message', this.messageHandler)
    }
  }

  setWidgetWidth() {
    let width = document.body.clientWidth - 20
    let units = 'px'
    if (width > 700) {
      width = 100
      units = '%'
    } else if (width < 300) {
      width = 300
    } else if (width < 450) {
      document.getElementById('xb-close-modal').style.right = 'calc(0%)'
    }
    if ((document.body.clientWidth - width) % 2 !== 0) {
      width -= 1
    }
    this.domElements.container.style.width = `${width}${units}`
    this.resizeFlag = !this.resizeFlag
  }

  resizeHandler() {
    if (this.resizeFlag) {
      if (typeof requestAnimationFrame === 'undefined') {
        this.setWidgetWidth()
      } else {
        requestAnimationFrame(this.setWidgetWidth)
      }
    } else {
      this.resizeFlag = !this.resizeFlag
    }
  }

  messageHandler(event) {
    if (event.data.type === 'heightEvent') {
      if (event.data.size !== parseFloat(this.domElements.container.style.height, 10)) {
        const widgetHeight = event.data.size + 1
        this.domElements.container.style.height = `${widgetHeight}px`
        this.domElements.cover.style.height = `${widgetHeight + 195}px`
      }
    } else if (event.data.type === 'dismiss') {
      this.dismissWidget()
    }
  }
}

function buildResWidgetScene(WUtil) {
  const scene = document.createElement('div')
  const cover = document.createElement('div')
  const outerContainer = document.createElement('div')
  const container = document.createElement('div')
  const meta = document.createElement('meta')
  const xButton = document.createElement('button')
  const iframe = document.createElement('iframe')
  const focusable = document.createElement('button')

  scene.style.top = '0px'
  scene.style.right = '0px'
  scene.style.bottom = '0px'
  scene.style.left = '0px'
  scene.style.zIndex = '9999999'
  scene.style.webkitOverflowScrolling = 'touch'
  scene.style.position = 'fixed'
  if (WUtil.getIsIOS()) {
    scene.style.height = `${document.documentElement.clientHeight}px`
    scene.style.overflowY = 'auto'
  } else {
    scene.style.overflowY = 'hidden'
  }

  cover.style.background = 'rgba(26,25,25,0.901961)'
  cover.style.top = '0px'
  cover.style.right = '0px'
  cover.style.left = '0px'
  cover.style.minHeight = '100%'
  cover.style.height = '395px'
  cover.style.opacity = '1'
  cover.style.position = 'absolute'

  outerContainer.style.width = '100%'
  outerContainer.style.margin = '40px auto 0px'

  container.style.boxShadow = 'rgba(0,0,0,0.298039) 0px 0px 3px'
  container.style.height = '200px'
  container.style.margin = '40px auto 0'
  container.style.opacity = '1'
  container.style.position = 'relative'
  container.style.transform = 'translate3d(0px, 0px, 0px)'

  meta.name = 'viewport'
  meta.content = 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0'

  xButton.style.color = 'rgb(255, 255, 255)'
  xButton.style.cursor = 'pointer'
  xButton.style.fontFamily = 'sans serif'
  xButton.style.position = 'absolute'
  xButton.style.fontSize = '36px'
  xButton.style.fontWeight = '200'
  xButton.style.right = 'calc(50% - 350px)'
  xButton.style.top = '-44px'
  xButton.style.width = '40px'
  xButton.style.lineHeight = '44px'
  xButton.style.textAlign = 'right'
  xButton.style.backgroundColor = 'transparent'
  xButton.style.border = '0'
  xButton.textContent = '×'
  xButton.setAttribute('aria-label', 'close modal')
  xButton.setAttribute('id', 'xb-close-modal')
  xButton.addEventListener('click', WUtil.dismissWidget)

  iframe.style.border = 'none'
  iframe.style.height = '90vh'
  iframe.style.width = '100%'
  iframe.style.backgroundColor = 'transparent'

  focusable.style.opacity = 0
  focusable.setAttribute('aria-label', 'end of modal')
  focusable.style.width = 0
  focusable.style.height = 0

  scene.appendChild(cover)
  scene.appendChild(outerContainer)

  outerContainer.appendChild(container)
  container.appendChild(xButton)
  container.appendChild(iframe)
  container.appendChild(focusable)

  return { scene, cover, container, meta, xButton, iframe, focusable }
}
