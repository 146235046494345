import './utils/polyfillEventListener'
import EventsWidgetLauncher from './utils/EventsWidgetLauncher'
import { includes } from './utils/helpers'
import ResWidgetLauncher from './utils/ResWidgetLauncher'
import { SubscriptionWidgetLauncher } from './utils/SubscriptionWidgetLauncher'
import WaitlistWidgetLauncher from './utils/WaitlistWidgetLauncher'

export default env => {
  let EventsLauncher
  let ResLauncher
  let WaitlistLauncher
  let SubscriptionLauncher

  window.SevenroomsWidget = {
    init: config => {
      if (!Object.keys(config).includes('env')) {
        Object.assign(config, { env })
      }

      if (config.type === 'events') {
        if (!EventsLauncher) {
          EventsLauncher = new EventsWidgetLauncher()
        }
        EventsLauncher.initWidget(config)
      } else if (config.type === 'reservations') {
        if (!ResLauncher) {
          ResLauncher = new ResWidgetLauncher()
        }
        ResLauncher.initWidget(config)
      } else if (config.type === 'waitlist') {
        if (!WaitlistLauncher) {
          WaitlistLauncher = new WaitlistWidgetLauncher()
        }
        WaitlistLauncher.initWidget(config)
      } else if (config.type === 'subscription') {
        if (!SubscriptionLauncher) {
          SubscriptionLauncher = new SubscriptionWidgetLauncher()
        }
        SubscriptionLauncher.initWidget(config)
      }
    },
  }

  const srResTrigger =
    document.getElementById('sr-res-root') || document.getElementById('sr-root') || document.getElementById('sr-res-root-custom')

  if (window.SEVENROOMS_VENUE_ID && srResTrigger) {
    const styleButton = includes(['sr-res-root', 'sr-root'], srResTrigger.id)
    window.SevenroomsWidget.init({
      venueId: window.SEVENROOMS_VENUE_ID,
      triggerId: srResTrigger.id,
      type: 'reservations',
      styleButton,
      env,
    })
  }

  const srWaitlistTrigger = document.getElementById('sr-waitlist-root') || document.getElementById('sr-waitlist-root-custom')

  if (window.SEVENROOMS_VENUE_ID && srWaitlistTrigger) {
    const styleButton = includes(['sr-waitlist-root'], srWaitlistTrigger.id)
    window.SevenroomsWidget.init({
      venueId: window.SEVENROOMS_VENUE_ID,
      triggerId: srWaitlistTrigger.id,
      type: 'waitlist',
      styleButton,
      env,
    })
  }

  const srEventsTrigger = document.getElementById('sr-events-root')

  if (window.SEVENROOMS_VENUE_ID && srEventsTrigger) {
    window.SevenroomsWidget.init({
      venueId: window.SEVENROOMS_VENUE_ID,
      triggerId: srEventsTrigger.id,
      type: 'events',
      styleButton: true,
      env,
    })
  }

  const srSubscriptionTrigger = document.getElementById('sr-subscription-root')

  if (window.SEVENROOMS_VENUE_ID && srSubscriptionTrigger) {
    window.SevenroomsWidget.init({
      env,
      triggerId: srSubscriptionTrigger.id,
      type: 'subscription',
      venueId: window.SEVENROOMS_VENUE_ID,
    })
  }
}
