import { defineMessages } from '@sevenrooms/core/locales'

export const subscriptionWidgetMessages = defineMessages({
  resConfirmPageDietaryRestrictQuestion: {
    id: 'resConfirmPageDietaryRestrictQuestion',
    defaultMessage: 'Do you have any dietary restrictions?',
  },
  policyVenueGroupMarketing: {
    id: 'policyVenueGroupMarketing',
    defaultMessage:
      'From time to time, we’ll contact you via email to keep you in the loop about what’s happening at all our locations. By clicking this, you will be resubscribed to any of our locations from which you previously unsubscribed.',
  },
  policyUsTwilioSmsOptInSubfooter: {
    id: 'policyUsTwilioSmsOptInSubfooter',
    defaultMessage:
      '<i>For SMS, reply STOP to unsubscribe. Reply HELP for help. Message frequency varies. Message & data rates may apply.</i>',
  },
  emailSubscriptionHeader: {
    id: 'emailSubscriptionHeader',
    defaultMessage: 'Stay in touch',
  },
  policyVenueSpecificMarketing: {
    id: 'policyVenueSpecificMarketing',
    defaultMessage: 'From time to time, we’ll contact you via email to keep you in the loop about what’s happening at our venue.',
  },
  subscriptionWidgetGroupEmailOptIn: {
    id: 'subscriptionWidgetGroupEmailOptIn',
    defaultMessage: 'Email marketing from our other locations',
  },
  subscriptionWidgetVenueEmailOptIn: {
    id: 'subscriptionWidgetVenueEmailOptIn',
    defaultMessage: 'Email marketing from {venue}',
  },
  subscriptionWidgetVenueSmsOptIn: {
    id: 'subscriptionWidgetVenueSmsOptIn',
    defaultMessage: 'Text marketing from {venue}',
  },
  resConfirmPageError: {
    id: 'resConfirmPageError',
    defaultMessage: 'There seems to be an error',
  },
  policyVenueSpecificSmsMarketing: {
    id: 'policyVenueSpecificSmsMarketing',
    defaultMessage: 'From time to time, we’ll contact you via text to keep you in the loop about what’s happening at our venue.',
  },
  resWidgetCheckoutSubmitButtonLabel: {
    id: 'resWidgetCheckoutSubmitButtonLabel',
    defaultMessage: 'Submit',
  },
  resWidgetBirthdayLabel: {
    id: 'resWidgetBirthdayLabel',
    defaultMessage: 'Birthday',
  },
  resWidgetEmailLabel: {
    id: 'resWidgetEmailLabel',
    defaultMessage: 'Email',
  },
  resWidgetFirstNameLabel: {
    id: 'resWidgetFirstNameLabel',
    defaultMessage: 'First Name',
  },
  resWidgetLastNameLabel: {
    id: 'resWidgetLastNameLabel',
    defaultMessage: 'Last Name',
  },
  resWidgetPhoneNumberLabel: {
    id: 'resWidgetPhoneNumberLabel',
    defaultMessage: 'Phone Number',
  },
  resWidgetPostalCodeLabel: {
    id: 'resWidgetPostalCodeLabel',
    defaultMessage: 'Postal Code',
  },
  resWidgetDietaryRestrictionsLabel: {
    id: 'resWidgetDietaryRestrictionsLabel',
    defaultMessage: 'Dietary Restrictions',
  },
  resWidgetSalutationLabel: {
    id: 'resWidgetSalutationLabel',
    defaultMessage: 'Title',
  },
  resWidgetErrorsFieldRequired: {
    id: 'resWidgetErrorsFieldRequired',
    defaultMessage: 'Required',
  },
  resWidgetErrorsFieldInvalid: {
    id: 'resWidgetErrorsFieldInvalid',
    defaultMessage: 'Invalid',
  },
  // Placeholders till actual GFL Strings are generated
  subWidgetEmptyOptIn: {
    id: 'subWidgetEmptyOptIn',
    defaultMessage: 'Please Make a selection',
  },
  subWidgetInvalidEmail: {
    id: 'subWidgetInvalidEmail',
    defaultMessage: 'Please Enter a valid email address',
  },
  subWidgetInvalidPhoneNumber: {
    id: 'subWidgetInvalidEmail',
    defaultMessage: 'Please Enter a valid phone number',
  },
  // Not Currently Translated
  headerAlt: {
    id: 'widget.venueImage.alt',
    defaultMessage: '{venue_name} Header Image',
  },
  venue: {
    id: 'widget.venueImage.venueLabel',
    defaultMessage: 'Venue',
  },
})
